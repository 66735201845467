import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/browser";
import { SENTRY_DSN } from "config/sentry";

if (process.env.NODE_ENV === "production") {
  Sentry.init({ dsn: SENTRY_DSN });
}

ReactDOM.render(<App />, document.getElementById("root"));
