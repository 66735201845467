import React from "react";
import { StyleSheet, css } from "aphrodite";

import FormItem from "common/FormItem";
import FormSelect from "common/FormSelect";

import colors from "common/colors";
import CartManager from "checkout/CartManager";

import manhattanDarkRed from "manhattan_editor/manhattan-dark-red-cord.jpg";
import manhattanDarkBlack from "manhattan_editor/manhattan-dark-black-cord.jpg";
import manhattanLightRed from "manhattan_editor/manhattan-light-red-cord.jpg";
import manhattanLightBlack from "manhattan_editor/manhattan-light-black-cord.jpg";

import { PRICE } from "checkout/config";

export const PANEL_WIDTH_PX = 300;

export const getManhattanOrderImage = order => {
  if (order.theme.value === "dark") {
    if (order.cordColor.value === "red") {
      return manhattanDarkRed;
    } else {
      return manhattanDarkBlack;
    }
  } else {
    if (order.cordColor.value === "red") {
      return manhattanLightRed;
    } else {
      return manhattanLightBlack;
    }
  }
};

function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

const parseUrl = queryString => {
  const params = new URLSearchParams(queryString);
  const getParam = key =>
    params.get(key) === "undefined" ? null : params.get(key);
  const findOption = (options, key) =>
    options.find(option => option.value === key);

  return {
    theme: findOption(THEME_OPTIONS, getParam("theme")),
    frameFinish: findOption(FRAME_FINISH_OPTIONS, getParam("frameFinish")),
    cordColor: findOption(CORD_COLOR_OPTIONS, getParam("cordColor"))
  };
};

const THEME_OPTIONS = [
  { value: "dark", label: "Dark" },
  { value: "light", label: "Light" }
];

const FRAME_FINISH_OPTIONS = [{ value: "hardwood", label: "Hardwood" }];

const CORD_COLOR_OPTIONS = [
  { value: "red", label: "Red" },
  { value: "black", label: "Black" }
];

class Editor extends React.Component {
  constructor(props) {
    super(props);
    const params = parseUrl(props.location.search);
    // TODO: read from localStorage if url params are empty?
    this.state = {
      selectedTheme: params.theme || THEME_OPTIONS[1],
      selectedFrameFinish: params.frameFinish || FRAME_FINISH_OPTIONS[0],
      selectedCordColor: params.cordColor || CORD_COLOR_OPTIONS[0]
    };
  }

  geocoderContainerRef = React.createRef();

  persistStateDebounced = debounce(
    () => {
      this.updateUrl();
      this.updateCart();
    },
    500,
    false
  );

  getOrder = () => {
    return {
      model: "manhattan",
      theme: this.state.selectedTheme,
      frameFinish: this.state.selectedFrameFinish,
      cordColor: this.state.selectedCordColor
    };
  };

  updateCart = () => {
    CartManager.setOrder(this.getOrder());
  };

  updateUrl = () => {
    const params = new URLSearchParams({
      theme: this.state.selectedTheme.value,
      frameFinish: this.state.selectedFrameFinish.value,
      cordColor: this.state.selectedCordColor.value
    });
    this.props.history.replace(`?${params}`);
  };

  setAndPersistState = newState => {
    this.setState(newState);
    this.persistStateDebounced();
  };

  handleThemeSelect = selectedTheme =>
    this.setAndPersistState({ selectedTheme });
  handleFrameFinishSelect = selectedFrameFinish =>
    this.setAndPersistState({ selectedFrameFinish });
  handleCordColorSelect = selectedCordColor =>
    this.setAndPersistState({ selectedCordColor });

  handleNextClick = () => {
    this.updateCart();
    window.location.href = "/checkout";
  };

  render() {
    return (
      <div className={css(styles.container)}>
        <div className={css(styles.previewPanel)}>
          <h1 className={css(styles.header)}>
            <a href="/" className={css(styles.headerLink)}>
              Map Lamps
            </a>
          </h1>
          <img
            className={css(styles.image)}
            src={getManhattanOrderImage(this.getOrder())}
            alt="manhattan preview"
          />
          <div className={css(styles.copyright)}>© OpenStreetMap</div>
        </div>
        <div className={css(styles.formPanel)}>
          <div className={css(styles.formPanelInner)}>
            <h2 className={css(styles.formHeader)}>Design your Map Lamp</h2>
            <div className={css(styles.formItems)}>
              <FormItem name="Map theme">
                <FormSelect
                  value={this.state.selectedTheme}
                  onChange={this.handleThemeSelect}
                  options={THEME_OPTIONS}
                />
              </FormItem>
              <FormItem name="Cord Color">
                <FormSelect
                  value={this.state.selectedCordColor}
                  onChange={this.handleCordColorSelect}
                  options={CORD_COLOR_OPTIONS}
                />
              </FormItem>
            </div>
          </div>
          <div className={css(styles.footer)}>
            <div className={css(styles.total)}>{`Total: $${PRICE}`}</div>
            <button
              className={css(styles.nextButton)}
              onClick={this.handleNextClick}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Editor;

// <FormItem name="Special requests (optional)">
//   <textarea
//     className={css(styles.notes)}
//     placeholder="We may be able to accomodate further customization."
//   />
// </FormItem>

const styles = StyleSheet.create({
  nextButton: {
    backgroundColor: "#447AB1",
    color: "white",
    fontWeight: "bold",
    height: "50px",
    padding: "6px 20px",
    userSelect: "none",
    textTransform: "uppercase",
    fontSize: "15px",
    letterSpacing: "1.4px",
    ":hover": {
      cursor: "pointer"
    },
    ":active": {
      cursor: "pointer"
    },
    ":focus": { outline: 0 }
  },
  copyright: {
    position: "absolute",
    fontSize: "small",
    color: "gray",
    bottom: "5px",
    left: "5px",
    "@media (max-width: 800px)": {
      fontSize: "12px",
      left: "auto",
      right: "5px"
    },
    "@media (max-width: 400px)": {
      fontSize: "10px"
    }
  },
  notes: {
    fontSize: "16px",
    padding: "6px",
    height: "4em",
    "@media (max-width: 640px)": {
      flex: 1
    }
  },
  header: {
    position: "absolute",
    top: "20px",
    left: "20px",
    margin: 0,
    cursor: "pointer",
    "@media (max-width: 800px)": {
      top: "15px",
      left: "15px",
      fontSize: "18px"
    },
    "@media (max-width: 400px)": {
      top: "10px",
      left: "10px",
      fontSize: "12px"
    }
  },
  headerLink: {
    color: "black",
    textDecoration: "none",
    cursor: "pointer",
    fontFamily: "'Libre Baskerville', serif"
  },
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    "@media (max-width: 800px)": {
      display: "block",
      height: "auto"
    }
  },
  formPanel: {
    backgroundColor: "#EEE",
    position: "relative",
    display: "flex",
    flexDirection: "column"
  },
  formPanelInner: {
    width: "300px",
    margin: "20px",
    display: "flex",
    "@media (max-width: 800px)": {
      width: "auto"
    },
    flex: 1,
    flexDirection: "column"
  },
  previewPanel: {
    backgroundColor: "#FFF",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    flex: 1,
    "@media (max-width: 800px)": {
      height: "70vh"
    }
  },
  formItems: {
    flexGrow: 1,
    "@media (max-width: 800px)": {
      display: "flex",
      flexWrap: "wrap"
    }
  },
  footer: {
    padding: "25px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  total: {
    fontSize: "18px",
    fontWeight: "bold"
  },
  formHeader: {
    fontWeight: "bold",
    "@media (max-width: 800px)": {
      display: "none"
    }
  },
  hint: {
    marginTop: "5px",
    fontSize: "small",
    color: colors.textGray
  },
  image: {
    height: "95%"
  }
});
