let cached = null;

const isWebGlSupported = () => {
  if (cached === null) {
    cached = compute();
  }
  return cached;
};

const compute = () => {
  try {
    var canvas = document.createElement("canvas");
    return (
      !!window.WebGLRenderingContext &&
      (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
    );
  } catch (e) {
    return false;
  }
};

export default isWebGlSupported;
