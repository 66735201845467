import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Checkout from "checkout/Checkout";
import Editor from "editor/Editor";
import ManhattanEditor from "manhattan_editor/ManhattanEditor";
import Home from "home/Home";
import About from "about/About";

function App() {
  return (
    <BrowserRouter>
      <Route path="/" exact={true} component={Home} />
      <Route path="/about" component={About} />
      <Route path="/manhattan_editor" component={ManhattanEditor} />
      <Route path="/editor" component={Editor} />
      <Route path="/checkout" component={Checkout} />
    </BrowserRouter>
  );
}

export default App;
