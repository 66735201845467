import React, { useState } from "react";
import { StyleSheet, css } from "aphrodite";

import { StripeProvider, Elements } from "react-stripe-elements";

import colors from "common/colors";

import CartManager from "checkout/CartManager";
import CheckoutForm from "checkout/CheckoutForm";
import Preview from "checkout/Preview";

import { STRIPE_PUBLISHABLE_KEY } from "checkout/config";

const Checkout = props => {
  const [completedOrderId, setCompletedOrderId] = useState(null);
  const [promoCode, setPromoCode] = useState(null);

  const goBack = () => {
    props.history.goBack();
  };

  const handleOrderSubmitted = orderId => {
    setCompletedOrderId(orderId);
    CartManager.clearOrder();
  };

  const order = CartManager.getOrder();
  if (!order) {
    window.location.href = "/";
  }

  const goHome = () => {
    window.location.href = "/";
  };

  return (
    <StripeProvider apiKey={STRIPE_PUBLISHABLE_KEY}>
      <div className={css(styles.page)}>
        <div className={css(styles.innerPage)}>
          <div className={css(styles.header)}>
            <h1 className={css(styles.headerTitle)}>
              <a href="/" className={css(styles.headerLink)}>
                Map Lamps
              </a>
            </h1>
            {completedOrderId ? (
              <div onClick={goHome} className={css(styles.headerLink)}>
                ← Return to home page
              </div>
            ) : (
              <div onClick={goBack} className={css(styles.headerLink)}>
                ← Return to editor
              </div>
            )}
          </div>
          <div className={css(styles.lowerInnerPage)}>
            <Elements>
              <CheckoutForm
                order={order}
                promoCode={promoCode}
                completedOrderId={completedOrderId}
                onOrderSubmitted={handleOrderSubmitted}
              />
            </Elements>
            <Preview
              order={order}
              promoCode={promoCode}
              completedOrderId={completedOrderId}
              onValidPromoCodeEntered={setPromoCode}
            />
          </div>
        </div>
        <div className={css(styles.copyright)}>
          Preview map © Mapbox © OpenStreetMap
        </div>
      </div>
    </StripeProvider>
  );
};

export default Checkout;

const styles = StyleSheet.create({
  copyright: {
    position: "absolute",
    fontSize: "small",
    color: "gray",
    bottom: "5px",
    right: "5px"
  },
  checkout: {
    display: "flex"
  },
  preview: {
    boxSizing: "border-box",
    width: "300px",
    marginLeft: "30px",
    background: "white",
    position: "sticky",
    top: "30px",
    padding: "20px",
    alignSelf: "start",
    "@media (max-width: 820px)": {
      marginBottom: "30px",
      marginLeft: "0px",
      position: "static",
      alignSelf: "center",
      width: "100%"
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  headerTitle: {
    fontFamily: "'Libre Baskerville', serif",
    cursor: "pointer"
  },
  headerLink: {
    color: "black",
    textDecoration: "none",
    cursor: "pointer"
  },
  page: {
    position: "relative",
    padding: "20px",
    paddingBottom: "30px",
    background: colors.selectedGray,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  innerPage: {
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 820px)": {
      minWidth: "85%"
    }
  },
  lowerInnerPage: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 820px)": {
      flexDirection: "column-reverse",
      alignItems: "center"
    }
  }
});
