import React from "react";
import { StyleSheet, css } from "aphrodite";

import Header from "home/Header";
import Footer from "home/Footer";
import maxAndKarl from "about/max_and_karl.jpg";

export default function() {
  return (
    <div>
      <div className={css(styles.menuContainer)}>
        <Header color="black" />
      </div>
      <div className={css(styles.middleSection)}>
        <h2>About</h2>
        <div className={css(styles.content)}>
          <img
            src={maxAndKarl}
            className={css(styles.image)}
            alt="Max and karl on deck"
          />
          <p className={css(styles.description)}>
            Map Lamps is a project by brothers{" "}
            <a href="https://maxheinritz.com/">Max Heinritz</a> and{" "}
            <a href="http://hfbusiness.com/Portals/0/SliderCollection/783/HFB_40X40_Slideshow2018_17.jpg">
              Karl Heinritz
            </a>
            . It's born of Max's obsession with lighting, Karl's background in
            the furniture business, and a shared love of maps. You can reach us
            at hi@maplamps.com.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

const styles = StyleSheet.create({
  middleSection: {
    marginTop: "60px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80vw",
    "@media (min-width: 1100px)": {
      width: "900px"
    }
  },
  content: {
    display: "flex",
    "@media (max-width: 800px)": {
      flexDirection: "column-reverse"
    }
  },
  description: {
    marginTop: 0,
    marginLeft: "20px",
    lineHeight: "1.25em",
    "@media (max-width: 800px)": {
      marginLeft: 0
    }
  },
  image: {
    width: "300px",
    "@media (max-width: 800px)": {
      width: "100%"
    }
  },
  menuContainer: {
    width: "100vw",
    display: "flex",
    justifyContent: "center"
  }
});
