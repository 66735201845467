import React from "react";
import { StyleSheet, css } from "aphrodite";

import colors from "common/colors";
import Button from "common/Button";

import { PRICE, DISCOUNT_PRICE } from "checkout/config";

import CircularMapPreview from "checkout/CircularMapPreview";
import ManhattanMapPreview from "checkout/ManhattanMapPreview";

const Preview = props => {
  const { order, onValidPromoCodeEntered, promoCode } = props;
  const handlePromoCodeClicked = () => {
    const promoCode = prompt("Enter Map Lamps promotional code");
    // if you are reading this ... you win 10% off! =D
    if (promoCode === "map_lamp_champ") {
      onValidPromoCodeEntered(promoCode);
    } else if (promoCode) {
      alert("Oops! We didn't recognize that as a valid promotional code.");
    }
  };
  return (
    <div className={css(styles.preview)}>
      <div className={css(styles.container)}>
        {order.model === "circular" ? (
          <CircularMapPreview order={order} />
        ) : (
          <ManhattanMapPreview order={order} />
        )}
        <p className={css(styles.p)}>
          {promoCode ? (
            <React.Fragment>
              {`$${DISCOUNT_PRICE}`}
              <span className={css(styles.discount)}>($50 off!)</span>
            </React.Fragment>
          ) : (
            `$${PRICE}`
          )}
        </p>
        <div className={css(styles.hint)}>Includes tax and shipping.</div>
        {props.completedOrderId ? null : (
          <Button onClick={handlePromoCodeClicked} disabled={promoCode}>
            <span className={css(styles.promoText)}>Enter promo code</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default Preview;

const styles = StyleSheet.create({
  discount: {
    fontSize: "18px",
    marginLeft: "5px",
    color: "#228B22"
  },
  preview: {
    boxSizing: "border-box",
    width: "300px",
    marginLeft: "30px",
    background: "white",
    position: "sticky",
    top: "30px",
    padding: "20px",
    alignSelf: "start",
    "@media (max-width: 820px)": {
      marginBottom: "30px",
      marginLeft: "0px",
      position: "static",
      alignSelf: "center",
      width: "100%"
    }
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  p: {
    color: "black",
    fontSize: "32px",
    fontWeight: "bold",
    marginTop: "5px",
    marginBottom: "5px"
  },
  thumbnail: {
    width: "200px"
  },
  configTable: {
    tableLayout: "fixed",
    marginTop: "20px",
    marginBottom: "20px",
    width: "100%"
  },
  hint: {
    marginBottom: "20px",
    fontSize: "small",
    color: colors.textGray
  },
  promoText: {
    fontSize: "small",
    color: colors.textGray
  }
});
