import React from "react";
import { StyleSheet, css } from "aphrodite";

import { getManhattanOrderImage } from "manhattan_editor/ManhattanEditor";

const ManhattanMapPreview = props => {
  const config = props.order;
  return (
    <React.Fragment>
      <h3>Manhattan Map Lamp</h3>
      <img
        className={css(styles.thumbnail)}
        src={getManhattanOrderImage(config)}
        alt="thumbnail"
      />
      <table className={css(styles.configTable)}>
        <tbody>
          <ConfigItem name="Theme" value={config.theme.label} />
          <ConfigItem name="Cord color" value={config.cordColor.label} />
        </tbody>
      </table>
    </React.Fragment>
  );
};

const ConfigItem = ({ name, value }) => {
  return (
    <tr className={css(styles.item)}>
      <td className={css(styles.itemName)}>{name}</td>
      <td>{value}</td>
    </tr>
  );
};

export default ManhattanMapPreview;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  thumbnail: {
    width: "200px"
  },
  configTable: {
    tableLayout: "fixed",
    marginTop: "20px",
    marginBottom: "20px",
    width: "100%"
  },
  item: {},
  itemName: {
    color: "gray",
    textAlign: "right"
  }
});
