import "whatwg-fetch";

import { compressOrderDetailsForBackend } from "checkout/CartManager";

import { LAMBDA_API_GATEWAY_URL } from "checkout/config";
import * as Sentry from "@sentry/browser";

export const submitOrder = async (stripe, order, checkoutDetails) => {
  const compressedOrder = compressOrderDetailsForBackend(order);
  const orderId = generateOrderId();
  const orderDetails = {
    id: orderId,
    ...compressedOrder
  };
  const metadata = getStripeMetadata(orderDetails, checkoutDetails);

  // https://stripe.com/docs/stripe-js/reference#stripe-create-source
  // https://stackoverflow.com/questions/50284633/stripe-payments-source-vs-token-card
  const sourceParams = {
    type: "card",
    owner: {
      email: checkoutDetails.email,
      name: checkoutDetails.nameOnCard,
      phone: checkoutDetails.billingPhone,
      address: checkoutDetails.billingAddress
    },
    metadata
  };

  const { source } = await stripe.createSource(sourceParams);

  const chargeParams = {
    currency: "usd",
    source: source.id,
    description: `Map Lamp Order ${orderId}`,
    shipping: {
      name: checkoutDetails.shippingName,
      address: checkoutDetails.shippingAddress,
      phone: checkoutDetails.shippingPhone
    },
    metadata
  };

  await submitOrderToBackend(sourceParams, chargeParams);

  return orderId;
};

const submitOrderToBackend = async (sourceParams, chargeParams) => {
  const response = await fetch(LAMBDA_API_GATEWAY_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ sourceParams, chargeParams })
  });

  // We have to test for HTTP error status codes ourselves.
  // https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
  if (!response.ok) {
    const json = await response.json();
    const message = json.error;
    Sentry.captureMessage(`Error from lambda backend: ${message}`);
    throw Error(message);
  }
};

// Stripe metadata values can only be 500 chars, so
// we pull out special requests into a separate.
const getStripeMetadata = (orderDetails, checkoutDetails) => {
  const copied = Object.assign({}, orderDetails);
  delete copied.specialRequests;
  return {
    order: JSON.stringify(copied),
    specialRequests: orderDetails.specialRequests,
    promoCode: checkoutDetails.promoCode
  };
};

// yeah, not the best thing to do here
const generateOrderId = () => {
  return Math.floor(100000000 + Math.random() * 900000000);
};

// Notes:

// Frontend JS doesn't have access to CORS errors :(
// So in Sentry they just look like "failed to fetch"
// https://sentry.io/organizations/map-lamps/issues/1271195641
// https://stackoverflow.com/questions/42721584/catching-an-access-control-allow-origin-error-in-javascript/42723074#42723074

// TODO:
// Set up a lambda to allow localhost to access staging https://stackoverflow.com/a/41708323
// Need to use a lambda to get around CORS / API Gateway restrictions
