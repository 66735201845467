import React from "react";
import { StyleSheet, css } from "aphrodite";

export default function MapInteractionHint(props) {
  return (
    <div className={css(styles.container)}>
      Drag and zoom the map to pick a precise location.
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    color: "gray",
    position: "absolute",
    width: "200px",
    top: "20px",
    right: "20px",
    textAlign: "right",
    "@media (max-width: 800px)": {
      display: "none"
    },
    display: "flex"
  }
});
