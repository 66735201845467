import React, { useRef, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import colors from "common/colors";

import { useWindowWidth } from "@react-hook/window-size/throttled";
import FormItem from "checkout/FormItem";

import * as Sentry from "@sentry/browser";

import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from "react-stripe-elements";

import { submitOrder } from "checkout/submitOrder";

import AddressForm from "checkout/AddressForm";

const styles = StyleSheet.create({
  hint: {
    color: "gray",
    fontSize: "small"
  },
  cardMinorDetails: {
    display: "flex",
    justifyContent: "space-between"
  },
  form: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    width: "480px",
    padding: "20px",
    background: "white",
    alignSelf: "start",
    "@media (max-width: 850px)": {
      width: "100%"
    }
  },
  cardInput: {
    height: "20px",
    padding: "6px 12px",
    borderRadius: "4px",
    borderColor: colors.defaultGray,
    borderStyle: "solid",
    borderWidth: "1px"
  },
  ccMainWrapper: {
    marginLeft: "10px",
    width: "306px",
    "@media (max-width: 820px)": {
      marginLeft: "0px",
      width: "100%"
    }
  },
  ccBigScreenWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    lineHeight: "24px",
    marginTop: "15px",
    height: "34px",
    alignItems: "baseline"
  },
  ccDetailLabel: {
    flexGrow: 1,
    textAlign: "right",
    marginRight: "10px",
    display: "inline-block",
    color: "#555",
    alignSelf: "baseline",
    marginTop: "3px"
  },
  ccDetailWrapper: {
    width: "100px",
    "@media (max-width: 820px)": {
      width: "100%"
    }
  },
  ccDetailRowWrapper: {
    width: "306px",
    display: "flex",
    height: "20px",
    fontSize: "16px",
    lineHeight: "24px",
    "@media (max-width: 820px)": {
      marginLeft: "0px",
      flexGrow: 1,
      alignSelf: "stretch",
      width: "auto"
    }
  },
  formInput: {
    marginLeft: "10px",
    height: "20px",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "6px 12px",
    borderRadius: "4px",
    borderColor: colors.defaultGray,
    borderStyle: "solid",
    borderWidth: "1px",
    // https://stackoverflow.com/questions/12791631/remove-inner-shadow-of-text-input
    "-webkit-appearance": "none",
    boxShadow: "inset 0px 0px 0px 0px red",
    outline: "none",
    width: "280px",
    "@media (max-width: 820px)": {
      marginLeft: "0px",
      flexGrow: 1,
      alignSelf: "stretch",
      width: "auto"
    },
    ":focus": {
      outline: "none"
    }
  },
  shippingNote: {
    marginTop: "30px"
  },
  button: {
    backgroundColor: "#447AB1",
    color: "white",
    fontWeight: "bold",
    height: "50px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 10px",
    userSelect: "none",
    textTransform: "uppercase",
    fontSize: "15px",
    letterSpacing: "1.4px",
    ":hover": {
      cursor: "pointer"
    },
    ":active": {
      cursor: "pointer"
    },
    ":disabled": {
      backgroundColor: colors.defaultGray
    },
    ":focus": { outline: 0 }
  },
  error: {
    width: "100%",
    textAlign: "center",
    marginTop: "20px",
    color: "red"
  }
});

const ccStyle = {
  base: {
    fontSize: "16px",
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"`
  }
};

// TODO: Use formula one?

const CheckoutForm = props => {
  const windowWidth = useWindowWidth(
    360 /* initialWidth when there is no window */,
    { wait: 400 }
  );
  const [isAddressSame, setIsAddressSame] = useState(true);
  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
  const [isCardCvcComplete, setIsCardCvcComplete] = useState(false);
  const [isCardExpiryComplete, setIsCardExpiryComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const shippingFormRef = useRef();
  const billingFormRef = useRef();
  const emailRef = useRef();
  const nameOnCardRef = useRef();

  const extractCheckoutDetails = () => {
    const shippingRef = shippingFormRef;
    const billingRef = isAddressSame ? shippingFormRef : billingFormRef;
    return {
      email: emailRef.current.value,
      nameOnCard: nameOnCardRef.current.value,
      billingAddress: billingRef.current.getAddress(),
      billingPhone: billingRef.current.getPhone(),
      shippingName: shippingRef.current.getName(),
      shippingAddress: shippingRef.current.getAddress(),
      shippingPhone: shippingRef.current.getPhone(),
      promoCode: props.promoCode
    };
  };

  const submitForm = async () => {
    // Other fields are marked as required and handled by browser, but
    // we have to check the credit card fields ourselves.
    if (!isCardNumberComplete || !isCardCvcComplete || !isCardExpiryComplete) {
      setErrorMessage("Please fill in all credit card fields.");
      return;
    }

    const orderId = await submitOrder(
      props.stripe,
      props.order,
      extractCheckoutDetails()
    );

    props.onOrderSubmitted(orderId);
  };

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault();

    setIsSubmitting(true);

    try {
      await submitForm();
    } catch (error) {
      // hacky =D
      const isIntendedForUser =
        error.message && error.message.includes("Please");
      if (!isIntendedForUser) {
        Sentry.captureException(error);
      }
      setErrorMessage(
        isIntendedForUser
          ? error.message
          : "Unexpected error. Please email support@maplamps.com."
      );
    }

    setIsSubmitting(false);
  };

  if (props.completedOrderId) {
    return (
      <div className={css(styles.form)}>
        <h1>Order submitted</h1>
        <h2>Thanks!</h2>
        <p>Your order identifier is {props.completedOrderId}.</p>
        <p>
          Items typically ship within four to six weeks, depending on capacity.
          Please email support@maplamps.com if you have any questions.
        </p>
      </div>
    );
  }

  const desktopCardDetails = () => (
    <div className={css(styles.ccBigScreenWrapper)}>
      <div className={css(styles.ccDetailLabel)}>Card expiry</div>
      <div className={css(styles.ccDetailRowWrapper)}>
        <div className={css(styles.ccDetailWrapper)}>
          <CardExpiryElement
            style={ccStyle}
            className={css(styles.cardInput)}
            onChange={element =>
              !element.empty &&
              element.complete &&
              setIsCardExpiryComplete(true)
            }
          />
        </div>
        <div className={css(styles.ccDetailLabel)}>Card CVC</div>
        <div className={css(styles.ccDetailWrapper)}>
          <CardCVCElement
            style={ccStyle}
            className={css(styles.cardInput)}
            onChange={element =>
              !element.empty && element.complete && setIsCardCvcComplete(true)
            }
          />
        </div>
      </div>
    </div>
  );

  const mobileCardDetails = () => (
    <div className={css(styles.cardMinorDetails)}>
      <FormItem name="Card expiry">
        <div className={css(styles.ccDetailWrapper)}>
          <CardExpiryElement
            style={ccStyle}
            className={css(styles.cardInput)}
            onChange={element =>
              !element.empty &&
              element.complete &&
              setIsCardExpiryComplete(true)
            }
          />
        </div>
      </FormItem>
      <div style={{ width: "20px" }} />
      <FormItem name="Card CVC">
        <div className={css(styles.ccDetailWrapper)}>
          <CardCVCElement
            style={ccStyle}
            className={css(styles.cardInput)}
            onChange={element =>
              !element.empty && element.complete && setIsCardCvcComplete(true)
            }
          />
        </div>
      </FormItem>
    </div>
  );

  return (
    <form onSubmit={handleSubmit} className={css(styles.form)} name="checkout">
      <div>
        <FormItem name="Email address">
          <input
            ref={emailRef}
            className={css(styles.formInput)}
            required
            autoComplete="shipping email"
          />
        </FormItem>
        <h3>Shipping address</h3>
        <AddressForm ref={shippingFormRef} section="shipping" />
        <h3>Payment</h3>
        <p className={css(styles.hint)}>SSL Secured & Encrypted</p>
        <label>
          <input
            name="isBillingAddressSame"
            type="checkbox"
            checked={isAddressSame}
            onChange={event => setIsAddressSame(event.target.checked)}
          />
          Use shipping address for billing
        </label>
        <FormItem name="Name on card">
          <input
            ref={nameOnCardRef}
            className={css(styles.formInput)}
            autoComplete="billing name"
            required
          />
        </FormItem>
        {isAddressSame ? null : (
          <React.Fragment>
            <AddressForm
              ref={billingFormRef}
              section="billing"
              hideName={true}
            />
          </React.Fragment>
        )}
        <FormItem name="Card number">
          <div className={css(styles.ccMainWrapper)}>
            <CardNumberElement
              style={ccStyle}
              className={css(styles.cardInput)}
              onChange={element =>
                !element.empty &&
                element.complete &&
                setIsCardNumberComplete(true)
              }
            />
          </div>
        </FormItem>
        {windowWidth > 820 ? desktopCardDetails() : mobileCardDetails()}
      </div>
      <p className={css(styles.shippingNote)}>
        Lamps are made-to-order and typically ship in 4-6 weeks.
      </p>
      <button
        type="submit"
        className={css(styles.button)}
        disabled={isSubmitting}
      >
        {isSubmitting ? "Submitting order" : "Submit order"}
      </button>
      {errorMessage && !isSubmitting ? (
        <div className={css(styles.error)}>{errorMessage}</div>
      ) : null}
    </form>
  );
};

export default injectStripe(CheckoutForm);
