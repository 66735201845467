import React from "react";
import { StyleSheet, css } from "aphrodite";
import colors from "common/colors";

import FormItem from "checkout/FormItem";

const styles = StyleSheet.create({
  formInput: {
    marginLeft: "10px",
    height: "20px",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "6px 12px",
    borderRadius: "4px",
    borderColor: colors.defaultGray,
    borderStyle: "solid",
    borderWidth: "1px",
    // https://stackoverflow.com/questions/12791631/remove-inner-shadow-of-text-input
    "-webkit-appearance": "none",
    boxShadow: "inset 0px 0px 0px 0px red",
    outline: "none",
    width: "280px",
    "@media (max-width: 820px)": {
      marginLeft: "0px",
      flexGrow: 1,
      alignSelf: "stretch",
      width: "auto"
    },
    ":focus": {
      outline: "none"
    }
  }
});

// im lazy so this is an uncontrolled form
// https://reactjs.org/docs/uncontrolled-components.html
class AddressForm extends React.Component {
  nameRef = React.createRef();
  phoneRef = React.createRef();
  lineOneRef = React.createRef();
  lineTwoRef = React.createRef();
  cityRef = React.createRef();
  stateRef = React.createRef();
  postalCodeRef = React.createRef();
  countryRef = React.createRef();

  getAddress() {
    return {
      city: this.cityRef.current.value,
      country: this.countryRef.current.value,
      line1: this.lineOneRef.current.value,
      line2: this.lineTwoRef.current.value,
      postal_code: this.postalCodeRef.current.value,
      state: this.stateRef.current.value
    };
  }

  getName() {
    return this.nameRef.current.value;
  }

  getPhone() {
    return this.phoneRef.current.value;
  }

  render() {
    const { section } = this.props;
    return (
      <div>
        {this.props.hideName ? null : (
          <FormItem name="Name">
            <input
              ref={this.nameRef}
              className={css(styles.formInput)}
              required
              autoComplete={`${section} name`}
            />
          </FormItem>
        )}
        <FormItem name="Phone number">
          <input
            ref={this.phoneRef}
            className={css(styles.formInput)}
            type="tel"
            required
            autoComplete={`${section} phone`}
          />
        </FormItem>
        <FormItem name="Street address">
          <input
            ref={this.lineOneRef}
            className={css(styles.formInput)}
            required
            autoComplete={`${section} address-line1`}
          />
        </FormItem>
        <FormItem name="">
          <input
            ref={this.lineTwoRef}
            className={css(styles.formInput)}
            autoComplete={`${section} address-line2`}
          />
        </FormItem>
        <FormItem name="City">
          <input
            ref={this.cityRef}
            className={css(styles.formInput)}
            required
            autoComplete={`${section} address-level1`}
          />
        </FormItem>
        <FormItem name="State / Province">
          <input
            ref={this.stateRef}
            className={css(styles.formInput)}
            required
            autoComplete={`${section} address-level2`}
          />
        </FormItem>
        <FormItem name="Postal code">
          <input
            ref={this.postalCodeRef}
            className={css(styles.formInput)}
            required
            autoComplete={`${section} postal-code`}
          />
        </FormItem>
        <FormItem name="Country">
          <input
            ref={this.countryRef}
            className={css(styles.formInput)}
            required
            autoComplete={`${section} country`}
          />
        </FormItem>
      </div>
    );
  }
}

export default AddressForm;
