import React from "react";
import { StyleSheet, css } from "aphrodite";

const FormItem = ({ name, children }) => (
  <div className={css(formItemStyles.formItem)}>
    <FormItemLabel name={name} />
    {children}
  </div>
);

const formItemStyles = StyleSheet.create({
  formItem: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
    "@media (max-width: 820px)": {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },
  formLabel: {
    display: "inline-block",
    color: "#555",
    marginBottom: "5px"
  }
});

export const FormItemLabel = ({ name }) => (
  <label className={css(formItemStyles.formLabel)}>{name}</label>
);

export default FormItem;
