import React, { useState, createRef, useEffect } from "react";
import { StyleSheet, css } from "aphrodite";

import { useWindowWidth } from "@react-hook/window-size";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

// ratio for images should be 2 x 3 (w x h)
import brick from "home/images/carousel/brick.jpg";
import tree from "home/images/carousel/tree.jpg";
import white from "home/images/carousel/white.jpg";
import manhattanTree from "home/images/carousel/manhattan-tree.jpg";
import chicagoHay from "home/images/carousel/chicago-hay.jpg";
import manhattanNightMode from "home/images/carousel/manhattan-night-mode.jpg";

const IMAGES = [
  brick,
  tree,
  manhattanTree,
  white,
  chicagoHay,
  manhattanNightMode
];

let firstClientX, clientX;

const preventTouch = e => {
  const minValue = 5; // threshold

  clientX = e.touches[0].clientX - firstClientX;

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    e.preventDefault();
    e.returnValue = false;

    return false;
  }
};

const touchStart = e => {
  firstClientX = e.touches[0].clientX;
};

export default () => {
  const [hasSwiped, setHasSwiped] = useState(false);
  const containerRef = createRef();
  const sliderRef = createRef();
  const windowWidth = useWindowWidth(
    360 /* initialWidth when there is no window */,
    { wait: 100 }
  );
  const isBigScreen = windowWidth > 1100;
  const markHasSwiped = () => !hasSwiped && setHasSwiped(true);
  const handleClick = e => {
    const isTapOnLeftHalf = e.pageX < window.innerWidth / 2;
    if (isTapOnLeftHalf) {
      sliderRef.current.slickPrev();
    } else {
      sliderRef.current.slickNext();
    }
    markHasSwiped();
  };

  // https://github.com/akiran/react-slick/issues/1240#issuecomment-513235261
  useEffect(() => {
    const ref = containerRef.current;
    if (ref.current) {
      ref.addEventListener("touchstart", touchStart);
      ref.addEventListener("touchmove", preventTouch, {
        passive: false
      });
    }

    return () => {
      if (ref) {
        ref.removeEventListener("touchstart", touchStart);
        ref.removeEventListener("touchmove", preventTouch, {
          passive: false
        });
      }
    };
  });

  return (
    <div
      ref={containerRef}
      className={[css(styles.container), "map-lamps-carousel"].join(" ")}
      onClick={handleClick}
    >
      <Slider
        ref={sliderRef}
        autoplay={isBigScreen || !hasSwiped}
        speed={isBigScreen ? 1000 : 0}
        centerPadding="0px"
        className="slider variable-width"
        slidesToShow={isBigScreen ? 2 : 1}
        slidesToScroll={isBigScreen ? 2 : 1}
        pauseOnHover={true}
        onSwipe={markHasSwiped}
        dots
      >
        {IMAGES.map((image, index) => (
          <img
            key={index}
            className={css(styles.image)}
            src={image}
            alt={`gallery preview ${index}`}
          />
        ))}
      </Slider>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: "90px",
    marginBottom: "90px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "900px",
    "@media (max-width: 1099px)": {
      width: "50vw"
    },
    "@media (max-width: 700px)": {
      width: "80vw",
      marginTop: "60px",
      marginBottom: "60px"
    }
  },
  image: {
    maxWidth: "80vh",
    opacity: 1,
    transition: "opacity .5s",
    "@media (max-width: 1099px)": {
      width: "50vw"
    },
    "@media (max-width: 700px)": {
      width: "80vw"
    }
  }
});
