import React from "react";
import { StyleSheet, css } from "aphrodite";

import { FaInstagram } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";

export default class Header extends React.Component {
  render() {
    return (
      <div className={css(styles.menu)}>
        <div className={css(styles.leftMenu)}>
          <a
            href="/"
            className={css(styles.text)}
            style={{ color: this.props.color }}
          >
            <h1 className={css(styles.text)}>Map Lamps</h1>
          </a>
        </div>
        <div className={css(styles.rightMenu)}>
          <a
            href="https://www.pinterest.com/maplamps"
            className={css(styles.text, styles.rightMenuIcon)}
            style={{ color: this.props.color }}
          >
            <FaPinterest className={css(styles.a)} />
          </a>
          <a
            href="https://www.instagram.com/maplamps"
            className={css(styles.text, styles.rightMenuIcon)}
            style={{ color: this.props.color }}
          >
            <FaInstagram className={css(styles.a)} />
          </a>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  menu: {
    position: "relative",
    paddingTop: "30px",
    width: "80vw",
    "@media (min-width: 1100px)": {
      width: "900px"
    },
    "@media (max-width: 420px)": {
      paddingTop: "20px",
      width: "calc(100vw - 40px)"
    }
  },
  rightMenu: {
    position: "absolute",
    right: 0
  },
  rightMenuIcon: {
    padding: "10px"
  },
  leftMenu: {
    position: "absolute",
    left: 0
  },
  text: {
    fontFamily: "'Libre Baskerville', serif",
    textDecoration: "none",
    cursor: "pointer",
    fontSize: "24px",
    fontWeight: "normal",
    margin: 0,
    "@media (max-width: 420px)": {
      fontSize: "18px"
    }
  }
});
