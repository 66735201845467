import React, { useState } from "react";
import { StyleSheet, css } from "aphrodite";

import { useWindowWidth } from "@react-hook/window-size";

import colors from "common/colors";

import lakeImage from "home/images/lake.jpg";
import whiteImage from "home/images/white.jpg";

import { FaChevronDown } from "react-icons/fa";

import Header from "home/Header";
import Search from "home/Search";

export default function() {
  const windowWidth = useWindowWidth(
    360 /* initialWidth when there is no window */,
    { wait: 100 }
  );
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <div>
      <div className={css(styles.topSection)}>
        <div className={css(styles.imageContainer)}>
          <img
            className={[
              css(styles.image),
              isImageLoaded
                ? css(styles.imageLoaded)
                : css(styles.imageNotLoaded)
            ].join(" ")}
            onLoad={() => setIsImageLoaded(true)}
            src={windowWidth < 768 ? whiteImage : lakeImage}
            alt="main map lamp background"
          />
        </div>
        <div className={css(styles.taglineContainer)}>
          <h2 className={css(styles.tagline)}>
            Light up
            <br /> your place.
          </h2>
          <div className={css(styles.search)}>
            <Search backgroundColor="#eb4f47" />
          </div>
        </div>
        <div className={css(styles.menuContainer)}>
          <Header color="white" />
        </div>
        <div className={css(styles.notch)}>
          <FaChevronDown
            onClick={() => {
              window.scrollTo(0, 100 + window.innerHeight * 0.9);
            }}
            className={css(styles.arrow)}
          />
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  imageContainer: {
    position: "absolute",
    width: "100vw",
    height: "95vh",
    backgroundColor: "black",
    "@media (max-width: 767px)": {
      height: "85vh"
    }
  },
  topSection: {
    position: "relative",
    width: "100vw",
    height: "95vh",
    "@media (max-width: 767px)": {
      height: "85vh"
    }
  },
  tagline: {
    fontFamily: "'Libre Baskerville', serif",
    textAlign: "center",
    color: "white",
    fontSize: "48px",
    paddingTop: "10px",
    paddingBottom: "0px",
    fontWeight: "bold",
    textShadow: "0px 0px 10px rgb(225, 185, 141, .8)",
    "@media (max-height: 820px)": {
      fontSize: "36px",
      paddingTop: "0px",
      paddingBottom: "115px"
    },
    // iPhone X/XS 375x812
    // Pixel 2 XL 411x823
    "@media (max-height: 850px)": {
      fontSize: "28px",
      paddingTop: "0px",
      paddingBottom: "40px"
    },
    // iPhone 6/7/8 Plus 414x736
    // iPhone 6/7/8 375x667
    "@media (max-height: 700px)": {
      fontSize: "24px",
      paddingTop: "0px",
      paddingBottom: "10px"
    },
    // iPhone 5/SE 320x568
    "@media (max-height: 570px)": {
      fontSize: "24px",
      paddingTop: "20px",
      paddingBottom: "0px"
    },
    // Landscape
    "@media (max-height: 400px)": {
      fontSize: "24px",
      paddingTop: "0px",
      paddingBottom: "0px"
    }
  },
  menuContainer: {
    position: "absolute",
    width: "100vw",
    display: "flex",
    justifyContent: "center"
  },
  taglineContainer: {
    width: "100vw",
    height: "95vh",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: 0
  },
  imageNotLoaded: {
    opacity: 0
  },
  imageLoaded: {
    opacity: 0.8,
    transition: "opacity .5s ease-in-out"
  },
  notch: {
    position: "absolute",
    bottom: "-55px",
    width: "80px",
    height: "80px",
    left: "50%",
    marginLeft: "-40px",
    borderRadius: "40px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  arrow: {
    height: "20px",
    width: "20px",
    padding: "20px",
    borderRadius: "30px",
    ":hover": {
      cursor: "pointer",
      backgroundColor: colors.defaultGray
    },
    ":active": {
      backgroundColor: colors.defaultGray,
      cursor: "pointer"
    },
    userSelect: "none",
    color: colors.textGray,
    backgroundColor: colors.selectedGray
  },
  search: {
    paddingTop: "160px",
    flexDirection: "column",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 0,
    borderRadius: 0,
    // Landscape
    "@media (max-height: 400px)": {
      paddingTop: "30px"
    }
  }
});
