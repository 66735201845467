const STAGE = "prod"; // stage or prod

export const PRICE = 299;
export const DISCOUNT_PRICE = 249;

// Should include trailing "/".
const API_GATEWAY_BASE =
  "https://fw9912c7gd.execute-api.us-west-2.amazonaws.com/";
const API_GATEWAY_METHOD = "/submit_order/";

const getApiGatewayUrl = (stage) => {
  return API_GATEWAY_BASE + stage + API_GATEWAY_METHOD;
};

const getStripePublishableKey = (stage) => {
  if (stage === "prod") {
    return "pk_live_oxpVKRKnwxGdrbN5XCFjTIkh00DdgpR2Oq";
  }
  return "pk_test_51GrDbqJriUrNZtPtGxnSjipfXsJiUu43lQjD7PPxe7haW2DKQdJHskC5XT8moOAf2P1A6sOvUt23HKuD6crDIC1J00156JsP0j";
};

export const STRIPE_PUBLISHABLE_KEY = getStripePublishableKey(STAGE);
export const LAMBDA_API_GATEWAY_URL = getApiGatewayUrl(STAGE); // Stage or Prod
