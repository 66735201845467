class CartManager {
  setOrder(orderDetails) {
    localStorage.setItem("mapLampsOrderDetails", JSON.stringify(orderDetails));
  }
  clearOrder() {
    localStorage.removeItem("mapLampsOrderDetails");
  }
  getOrder() {
    return JSON.parse(localStorage.getItem("mapLampsOrderDetails"));
  }
}

export const compressOrderDetailsForBackend = order => {
  const compressed = {};
  Object.keys(order).forEach(key => {
    if (
      typeof order[key] === "object" &&
      order[key] !== null &&
      order[key].value
    ) {
      // The backend only needs the `value` from eg this:
      // order.density = {value: "foo_bar", label: "Foo Bar"}
      compressed[key] = order[key].value;
    } else {
      compressed[key] = order[key];
    }
  });
  return compressed;
};
const instance = new CartManager();
Object.freeze(CartManager);

export default instance;
