import React from "react";
import { StyleSheet, css } from "aphrodite";

import ReactMapGL from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import "mapbox-geocoder.css";
import "./geocoder-overrides.css";

import { MAPBOX_TOKEN } from "config/mapbox.js";

import isWebGlSupported from "common/isWebGlSupported";

class Search extends React.Component {
  mapRef = React.createRef();
  geocoderContainerRef = React.createRef();
  state = { bounds: [], placeName: "" };

  handleClick = () => {
    this.launchEditor();
  };

  handleResult = ({ result }) => {
    const { bbox, place_name, center } = result;
    let bounds;
    const OFFSET_LON = 0.02;
    const OFFSET_LAT = 0.02;
    if (bbox) {
      bounds = bbox;
    } else if (center) {
      bounds = [
        center[0] - OFFSET_LON,
        center[1] - OFFSET_LAT,
        center[0] + OFFSET_LON,
        center[1] + OFFSET_LAT
      ];
    } else {
      alert("Unexpected error. Please contact max@maplamps.com.");
      throw new Error(`Expected bbox or center. Got: ${result}`);
    }
    this.setState({ bounds, placeName: place_name });
    this.launchEditor();
  };

  launchEditor = () => {
    const { bounds, placeName } = this.state;
    const path = `/editor?bounds=${bounds.join(",")}&placeName=${placeName}`;
    window.location.href = path;
  };

  render() {
    return (
      <div className={css(styles.container)}>
        <div className={[css(styles.launch), "home-search-section"].join(" ")}>
          <div ref={this.geocoderContainerRef} />
          <button
            className={[
              css(
                !isWebGlSupported() && styles.roundedLeftButton,
                styles.button
              )
            ]}
            style={{ backgroundColor: this.props.backgroundColor || "gray" }}
            onClick={this.handleClick}
          >
            Get started
          </button>
        </div>
        <div
          style={{
            position: "absolute",
            visibility: "hidden",
            height: "1px",
            width: "1px"
          }}
        >
          {isWebGlSupported() ? (
            <ReactMapGL
              mapboxApiAccessToken={MAPBOX_TOKEN}
              ref={this.mapRef}
              onViewportChange={this.handleViewportChange}
              width="100%"
              height="100%"
            >
              {this.mapRef ? (
                <Geocoder
                  mapRef={this.mapRef}
                  containerRef={this.geocoderContainerRef}
                  placeholder={`Type any location...`}
                  inputValue={this.state.place}
                  onResult={this.handleResult}
                  mapboxApiAccessToken={MAPBOX_TOKEN}
                  enableEventLogging={false}
                />
              ) : null}
            </ReactMapGL>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Search;

const styles = StyleSheet.create({
  search: {
    position: "relative",
    flexDirection: "column",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  launch: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    boxShadow: "0px 0px 10px #555",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      boxShadow: "none"
    }
  },
  button: {
    margin: 0,
    color: "white",
    fontWeight: "bold",
    height: "50px",
    borderStyle: "solid",
    borderLeftWidth: 0,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 20px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    userSelect: "none",
    textTransform: "uppercase",
    fontSize: "15px",
    letterSpacing: "1.4px",
    border: "none",
    ":hover": {
      cursor: "pointer"
    },
    ":active": {
      cursor: "pointer"
    },
    ":focus": { outline: 0 },
    "@media (max-width: 600px)": {
      boxShadow: "0px 0px 10px #555",
      borderRadius: "4px",
      width: "280px",
      borderLeftWidth: "1px",
      fontSize: "12px",
      padding: "4px 16px",
      height: "42px"
    }
  },
  roundedLeftButton: {
    boxShadow: "0px 0px 10px #555",
    borderRadius: "4px",
    width: "280px",
    borderLeftWidth: "1px",
    fontSize: "12px",
    padding: "4px 16px",
    height: "42px"
  }
});
