import React from "react";
import { StyleSheet, css } from "aphrodite";

import colors from "./colors";

export default function(props) {
  return (
    <button
      className={[props.className, css(styles.button)].join("  ")}
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

const styles = StyleSheet.create({
  button: {
    padding: "10px 20px",
    borderColor: colors.defaultGray,
    borderStyle: "solid",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    margin: "5px",
    userSelect: "none",
    fontSize: "16px",
    ":hover": {
      backgroundColor: colors.hoveredGray,
      cursor: "pointer"
    },
    ":active": {
      backgroundColor: colors.selectedGray,
      cursor: "pointer"
    },
    ":disabled": {
      backgroundColor: colors.white,
      cursor: "default"
    },
    ":focus": { outline: 0 }
  }
});
